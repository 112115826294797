import React from "react"
import achievementStyles from './a5_achievement.module.css'
import LayoutA5 from "../../components/layout-a5"

export default class A5Achievement extends React.Component {
  render() {
    const color="#0000ff"
    return(
      <LayoutA5 >
        <div className={achievementStyles.achievement}>
          <div className={achievementStyles.scanCodeTitle} style={{color:color}}>扫码查看</div>
          <div className={achievementStyles.code}><img alt="hello" src={require("../../images/a2/race_code.png")}></img></div>
          <div className={achievementStyles.scanCodeDesc}>打开微信扫一扫</div>
          <div  className={achievementStyles.toWx}>打开微信查看成绩</div>
          <div className={achievementStyles.rankingContainer}>
            {/*<div className="ranking-icon"><img  alt="hello" src={require(this.state.width<480?'../images/ranking-phone.png':"../images/rankings.png")}/></div>*/}
            <div className={achievementStyles.achievementRanking}>
              {/*{ranking}*/}
              <img alt="hello" src={require('../../images/a2/ranking.png')}/>
            </div>
          </div>
        </div>
      </LayoutA5>
    )
  }
}